<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid login-background"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
      style="min-height: 100vh; min-width:100%;"
    >
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-10 my-auto mx-auto rounded-lg bg-white"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-5 pt-lg-0 pt-5">
                <v-row class="d-flex justify-center align-center">
                  <v-col class="mb-5 d-flex justify-center">
                    <div
                      :style="{ backgroundImage: `url(${logoRedondo})` }"
                      class="login-logo"
                      alt="Logo ITR"
                    ></div>
                  </v-col>
                </v-row>
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg text-center mb-5"
                >
                  Bienvenido al <br />
                  Portal Estudiantil ITR
                </h3>
                <span
                  class="text-muted font-weight-bold font-size-h5 text-justify"
                  >Ingresa tus credenciales para acceder al sistema
                </span>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Correo electrónico institucional</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="email"
                    name="email"
                    ref="email"
                    v-model.trim="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Contraseña</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 d-none"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >¿Olvidaste tu contraseña?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3 btn-block mb-4"
                >
                  Iniciar sesión
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-5 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg text-center mb-5"
                >
                  ¿Olvidaste tu contraseña?
                </h3>
                <p
                  class="text-muted font-weight-bold font-size-h5 text-justify"
                >
                  Ingresa tu correo electrónico asociado a tu cuenta al que te
                  enviaremos un correo para restablecer tu contraseña
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Correo electrónico"
                  name="email"
                  v-model="forgot.email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_forgot_submit"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <span class="font-weight-bolder font-size-h7 text-center mx-3"
            >Desarrollado por el departamento de Desarrollo de Software del
            <a
              href="https://www.ricaldone.edu.sv"
              target="_blank"
              class="text-primary font-weight-bolder font-size-h7"
              >Instituto Técnico Ricaldone</a
            ></span
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
export default {
  name: "Login",
  title: "Inicio de sesión | PE",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
      forgot: {
        email: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/wallpaper.svg";
    },
    logoRedondo() {
      return process.env.BASE_URL + "media/logos/logo_redondo.svg";
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message:
                "El campo correo electrónico institucional es obligatorio",
            },
            emailAddress: {
              message:
                "El dato ingresado no es una dirección de correo electrónico válida.",
            },
            stringLength: {
              max: 100,
              message:
                "El correo institucional no deberia contener más de 100 caracteres",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "El campo contraseña es obligatorio",
            },
            stringLength: {
              max: 100,
              message:
                "La contraseña no deberia contener más de 100 caracteres",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message:
                "El campo correo electrónico institucional es obligatorio",
            },
            emailAddress: {
              message:
                "El dato ingresado no es una dirección de correo electrónico válida",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;
      // clear existing errors
      this.$store.dispatch(LOGOUT);
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then((data) => {
          if (data.status) {
            this.$router.push({ name: "dashboard" });
            this.fireToast({
              icon: "success",
              title: data.message,
            });
            return;
          }
          this.sweetAlertResponse(data);
        })
        .catch((error) => {
          this.sweetAlertResponse(error);
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });
    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Por favor, proporciona datos correctos",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    this.fv2.on("core.form.valid", () => {
      var email = this.forgot.email;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // 🚩 Importar REQUEST_RESET_PASSWORD de auth.module cuando este, dar logica al metodo de recuperar contraseña
      this.$store
        .dispatch(REQUEST_RESET_PASSWORD, { email })
        // go to which page after successfully login
        .then((data) => {
          this.sweetAlert(data);
          this.$router.push({ name: "dashboard" });
        })
        .catch((response) => {
          this.sweetAlert({
            status: false,
            message:
              "Ha ocurrido un error al intentar conectar con el servidor 🙁",
          });
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv2.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Por favor, proporciona datos correctos",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>

<style scoped>
.login-logo {
  height: 100px;
  width: 100px;
  margin: 0px auto !important;
}

.login-background {
  background-image: url(`${backgroundImage}`);
  background-color: #cccccc;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
